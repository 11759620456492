var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-nav-item-dropdown',{staticClass:"dropdown-user",attrs:{"right":"","toggle-class":"d-flex align-items-center dropdown-user-link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-sm-flex d-none user-nav"},[_c('p',{staticClass:"user-name mb-0"},[_vm._v(" "+_vm._s(_vm.userData ? _vm.userData.user_alias || _vm.userData.username : "")+" ")]),_c('span',{staticClass:"user-status"},[_vm._v(_vm._s(_vm.$t(_vm.userRole)))])]),_c('b-avatar',{attrs:{"variant":"light-primary"}},[_c('b-icon-person-fill',{attrs:{"scale":"1.5"}})],1)]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"to":{
            name: 'admin-account-setting',
            params: { title: 'Account Settings' },
        },"link-class":"d-flex align-items-center"}},[_c('b-icon-gear-fill',{staticClass:"mr-1"}),_c('span',[_vm._v(_vm._s(_vm.$t("Account Settings")))])],1),_c('b-dropdown-item',{attrs:{"to":{
            name: 'admin-projects-list',
            params: {
                title: 'Projects Manage',
                icon: 'GridIcon',
                levels: [{ title: 'System Manage' }],
            },
        },"link-class":"d-flex align-items-center"}},[_c('b-icon-kanban-fill',{staticClass:"mr-1"}),_c('span',[_vm._v(_vm._s(_vm.$t("Projects Manage")))])],1),_c('b-dropdown-item',{attrs:{"to":{
            name: 'admin-users-list',
            params: {
                title: 'Users Manage',
                icon: 'UserIcon',
                levels: [{ title: 'System Manage' }],
            },
        },"link-class":"d-flex align-items-center"}},[_c('b-icon-people-fill',{staticClass:"mr-1"}),_c('span',[_vm._v(_vm._s(_vm.$t("Users Manage")))])],1),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":_vm.logout}},[_c('b-icon-door-closed-fill',{staticClass:"mr-1"}),_c('span',[_vm._v(_vm._s(_vm.$t("Logout")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }