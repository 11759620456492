<template>
    <b-nav-item-dropdown
        class="dropdown-user"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
    >
        <template #button-content>
            <div class="d-sm-flex d-none user-nav">
                <p class="user-name mb-0">
                    {{
                        userData ? userData.user_alias || userData.username : ""
                    }}
                </p>
                <span class="user-status">{{ $t(userRole) }}</span>
            </div>
            <b-avatar variant="light-primary">
                <b-icon-person-fill scale="1.5"></b-icon-person-fill>
            </b-avatar>
            <!-- <b-avatar
                v-if="userData && userData.avatar"
                :src="userData.avatar"
                badge
                badge-variant="success"
                class="badge-minimal border-success"
                size="40"
                variant="light-primary"
            >
                <feather-icon
                    v-if="!(userData && userData.user_name)"
                    icon="UserIcon"
                    size="22"
                />
            </b-avatar> -->
        </template>

        <b-dropdown-item
            :to="{
                name: 'admin-account-setting',
                params: { title: 'Account Settings' },
            }"
            link-class="d-flex align-items-center"
        >
            <b-icon-gear-fill class="mr-1"></b-icon-gear-fill>
            <span>{{ $t("Account Settings") }}</span>
        </b-dropdown-item>

        <b-dropdown-item
            :to="{
                name: 'admin-projects-list',
                params: {
                    title: 'Projects Manage',
                    icon: 'GridIcon',
                    levels: [{ title: 'System Manage' }],
                },
            }"
            link-class="d-flex align-items-center"
        >
            <b-icon-kanban-fill class="mr-1"></b-icon-kanban-fill>
            <span>{{ $t("Projects Manage") }}</span>
        </b-dropdown-item>

        <b-dropdown-item
            :to="{
                name: 'admin-users-list',
                params: {
                    title: 'Users Manage',
                    icon: 'UserIcon',
                    levels: [{ title: 'System Manage' }],
                },
            }"
            link-class="d-flex align-items-center"
        >
            <b-icon-people-fill class="mr-1"></b-icon-people-fill>
            <span>{{ $t("Users Manage") }}</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
            <b-icon-door-closed-fill class="mr-1"></b-icon-door-closed-fill>
            <span>{{ $t("Logout") }}</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import {
    BAvatar,
    BDropdownDivider,
    BDropdownItem,
    BNavItemDropdown,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import {
    computed,
    onMounted,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api";
import { logout } from "@/libs/utils/logout";
import { routerParams } from "@/libs/utils/routerParams";

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
    },
    setup() {
        let event = reactive({
            userData: computed(() => {
                let userData = {};
                if (
                    localStorage.getItem("userData") !== undefined ||
                    localStorage.getItem("userData") != null
                ) {
                    userData = JSON.parse(localStorage.getItem("userData"));
                }
                return userData;
            }),
            project_id: computed(() => {
                return routerParams("project_id");
            }),
            userRole: "",
            avatarText,
        });
        watch(
            () => event.project_id,
            () => {
                getUserRole();
            }
        );
        const getUserRole = () => {
            let role = "";
            if (event.userData?.projects) {
                event.userData.projects.map((project) => {
                    if (
                        parseInt(project.project_id) ===
                        parseInt(event.project_id)
                    ) {
                        role = project.role.name;
                    }
                });
            }
            event.userRole = role;
        };
        onMounted(() => {
            getUserRole();
        });
        return {
            ...toRefs(event),
            logout,
        };
    },
};
</script>
